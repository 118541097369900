import { DownOutlined } from "@ant-design/icons";
import { Button, Flex, notification, Popover, Space } from "antd";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { addTask } from "../../../service";
import { AddOrderModal } from "../../organisms";
import {
  createQuery,
  removeUndefinedFields,
  removeUnusedFields,
} from "../../organisms/filters/Filter.utils";
import { useFilters } from "../../templates/filter-context/useFilters";
import { TaskFilter } from "../tast-filter/TaskFilter";
import { GlobalActions } from "../global-actions/GlobalActions";
import TaskCalendarModal from "../task-calendar-modal/TaskCalendarModal";

export const TaskListHeader: React.FC<{
  setOffSet: any;
  setPage: any;
  hardcodedControls: any;
  total: any;
  global_actions: any;
}> = ({ setOffSet, setPage, hardcodedControls, total, global_actions }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { filters, setFilters } = useFilters();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: any) => {
    setVisible(visible);
  };

  const resetPagination = () => {
    setOffSet(0);
    setPage(1);
    const params = new URLSearchParams(location.search);
    params.delete("page");
  };

  const reset = () => {
    if (Object.keys(filters)?.length > 0) {
      setFilters({});
      resetPagination();
      navigate({
        pathname: location.pathname,
        search: "",
      });
    }
    setVisible(false);
  };

  const submit = (data: any) => {
    navigate({
      pathname: location.pathname,
      search: createQuery(removeUndefinedFields(data)),
    });
    setFilters(removeUndefinedFields(removeUnusedFields(data)));
    resetPagination();
    setVisible(false);
  };

  const { mutateAsync, isLoading } = useMutation(addTask, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/tasks");
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
      } catch (error) {
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        notification.error({
          message: "Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },

    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });
  return (
    <>
      <AddOrderModal
        open={open}
        setOpen={setOpen}
        mutateAsync={mutateAsync}
        isLoading={isLoading}
        hardcodedControls={hardcodedControls}
      />
      <Flex
        style={{
          paddingBottom: "8px",
        }}
        justify="space-between"
      >
        <Popover
          open={visible}
          onOpenChange={handleVisibleChange}
          placement="bottomLeft"
          trigger="click"
          title="Filtry"
          content={<TaskFilter onSubmit={submit} onReset={reset} />}
        >
          <Button icon={<DownOutlined />}>
            Filtruj
            {filters && Object.keys(filters)?.length > 0 && (
              <span>
                <div
                  style={{
                    borderRadius: "100%",
                    width: "22px",
                    height: "22px",
                    color: "white",
                    background: "#1677ff",
                    marginLeft: "10px",
                  }}
                >
                  {Object.keys(filters)?.length}
                </div>
              </span>
            )}
          </Button>
        </Popover>
        <Space>
          <TaskCalendarModal/>
          <GlobalActions total={total} global_actions={global_actions} />
        </Space>
      </Flex>
    </>
  );
};
