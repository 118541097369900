import { Calendar as ANTCalendar } from "antd";
import styled from "styled-components";

export const Calendar = styled(ANTCalendar)`
  .ant-picker-calendar-date-content {
    height: 50px !important;
  }
  .ant-picker-calendar-mode-switch {
    display: none;
  }
  .events {
    padding: 0;
    margin: 0;
  }
  && .ant-modal-body {
    margin-top: 40px !important;
  }
`;
