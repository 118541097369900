import React from "react";
import { statuses, priority } from "../../organisms/taksk-list/TaskList.utils";
import { Col as ANTCol, Flex, Tag } from "antd";
import styled from "styled-components";

export const Col = styled(ANTCol)`
  &:hover {
    background-color: lightgreen;
  }
`;

export const ModalTaskRow: React.FC<any> = ({ rowData }) => {
  return (
    <Col
      style={{
        width: "100%",
        padding: "10px",
        borderTop: "1px solid #ddd",
        borderLeft: "1px solid #ddd",
        borderRight: "1px solid #ddd",
        cursor: "pointer",
      }}
      onClick={()=> window.open(rowData.link, '_blank')}
    >
      <Flex
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>{rowData.title}</div>
        <div>
          <Tag
            color={statuses[rowData.status].color}
            key={statuses[rowData.status].color}
          >
            {statuses[rowData.status].text}
          </Tag>
          <Tag
            color={priority[rowData.priority].color}
            key={priority[rowData.priority].color}
          >
            {priority[rowData.priority].text}
          </Tag>
        </div>
      </Flex>
    </Col>
  );
};
