import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { QueryClient } from "react-query";
import { message, notification } from "antd";
export const PATH = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const autoLogout = () => {
  Cookies.remove("accessToken");
  notification.error({
    message: "Token autoryzacyjny wygasł, zaloguj się ponownie",
  });
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const getTasksStats = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/tasks-stats/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getOrders = async (id: number, filters: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/forms-list/data-model/${id}/?${filters}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const addOrder = async (data: { id: any; body: any }): Promise<any> => {
  return await axiosInstance.post<any>(
    `${PATH}/forms-list/data-model/${data.id}/`,
    data.body
  );
};

export const deleteOrder = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      `${PATH}/forms-list/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getFilters = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/filters/data-model/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const deleteAttach = async (attach_id: number): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      `${PATH}/attachments/${attach_id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const updateOrder = async (data: { id: any; body: any }) => {
  return await axiosInstance({
    method: "patch",
    url: `${PATH}/forms-list/${data.id}/`,
    data: data.body,
  });
};

export const updateTask = async (data: { id: any; body: any }) => {
  return await axiosInstance({
    method: "patch",
    url: `${PATH}/tasks/${data.id}/`,
    data: data.body,
  });
};

export const getModel = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/data-models/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAddModalControls = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/data-models/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

export const getTasks = async (filters: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/tasks/?${filters}`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getCalendarTasks = async (
  data: {
    first_calendar_day: string;
    last_calendar_day: string;
  },
  filters: any
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/tasks-calendar/?${filters}`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const addTask = async (data: { id: any; body: any }): Promise<any> => {
  return await axiosInstance.post<any>(`${PATH}/tasks/`, data.body);
};

export const deleteTask = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      `${PATH}/tasks/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getGetTaskDetails = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/tasks/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getComments = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/comments/task/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const addComment = async (data: {
  task: any;
  comment: any;
}): Promise<any> => {
  return await axiosInstance.post<any>(`${PATH}/comments/`, data);
};

export const updateComment = async (data: { id: any; comment: any }) => {
  return await axiosInstance.patch<any>(
    `${PATH}/comments/${data.id}/`,
    data.comment
  );
};

export const deleteComment = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      `${PATH}/comments/${id}/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const login = async (data: {
  username: any;
  password: any;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      `${PATH}/login/`,
      data
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const logout = async (): Promise<any> => {
  return await axiosInstance.post<any>(`${PATH}/logout/`);
};

export const getUsers = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/users/`
    );
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const getCurrentUser = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(
      `${PATH}/current_user/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFormModalControls = async (url: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(url);
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      autoLogout();
    }
    throw error;
  }
};

export const addFormModal = async (data: {
  url: string;
  body: any;
}): Promise<any> => {
  return await axiosInstance.post<any>(data.url, data.body);
};
