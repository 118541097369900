import React, { useEffect, useState } from "react";
import { Button, Modal, Badge, ConfigProvider, Row } from "antd";
import type { BadgeProps, CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import locale from "antd/lib/locale/pl_PL";
import { Calendar } from "./TaskCalendarModal.styled";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getCalendarTasks } from "../../../service";
import { ModalTaskRow } from "../../atoms/modal-task-row/ModalTaskRow";
import "dayjs/locale/pl";
import { priority } from "../../organisms/taksk-list/TaskList.utils";
import { useFilters } from "../../templates/filter-context/useFilters";
import { createQuery } from "../../organisms/filters/Filter.utils";
dayjs.locale("pl");

const TaskCalendarModal: React.FC = () => {
  dayjs.locale("pl");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [dataObject, setDataObject] = useState<any>(null);
  const [value, setValue] = useState<any>(null);
  const [taskList, setTaskList] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const getListData = (value: Dayjs) => {
    return taskList
      .filter((e: any) => value.format("YYYY-MM-DD") === e.due_to)
      .map((e: any) => {
        return {
          type: e.priority,
          content: e.title,
        };
      });
  };

  const getFirstAndLastDayOfMonth = (monthYear: string) => {
    const [month, year] = monthYear.split("-").map(Number);

    const firstDay = dayjs(`${year}-${month}-01`);

    const lastDay = firstDay.endOf("month");

    const formatDate = (date: any) => date.format("YYYY-MM-DD");

    return {
      first_calendar_day: formatDate(firstDay),
      last_calendar_day: formatDate(lastDay),
    };
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    
    return (
      <ul className="events">
        {listData.map((item: any) => (
          <li key={item.content}>
            <Badge
              color={priority[item.type].color}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };
  const { filters } = useFilters();


  const query = createQuery({ ...filters });

  useQuery<any, Error>(
    ["/celendar-tasks", dataObject],
    () => getCalendarTasks(dataObject, query),
    {
      onSuccess(data) {
        setTaskList(data);
      },
      enabled: !!dataObject && isModalOpen,
    }
  );

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  useEffect(() => {
    if (isModalOpen) {
      setDataObject(getFirstAndLastDayOfMonth(dayjs().format("MM-YYYY")));
    } else {
      setValue(dayjs());
    }
  }, [isModalOpen]);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Kalendarz
      </Button>
      <Modal
        
        width={1400}
        open={isModalOpen}
        closable={false}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        okText="Zamknij kalendarz"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {
         <ConfigProvider locale={locale}>
            <Calendar
              onSelect={(e, e2) => {
                if (e2.source === "date") {
                  setIsModalOpen2(true);
                  setSelectedDate(e);
                }
              }}
              value={value}
              cellRender={cellRender}
              mode="month"
              onChange={(e) => {
                setDataObject(
                  getFirstAndLastDayOfMonth(dayjs(e).format("MM-YYYY"))
                );
                setValue(dayjs(e));
              }}
            />
          </ConfigProvider>
        }

      </Modal>
      <Modal
        open={isModalOpen2}
        closable={false}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        okText="Zamknij"
        onOk={handleOk2}
        onCancel={handleCancel2}
      >
        <Row
          style={{
            borderBottom: "1px solid #ddd",
          }}
        >
          <>
            {taskList
              .filter(
                (e: any) => selectedDate?.format("YYYY-MM-DD") === e.due_to
              )
              .map((e: any) => {
                return <ModalTaskRow key={JSON.stringify(e)} rowData={e} />;
              })}
          </>
        </Row>
      </Modal>
    </>
  );
};

export default TaskCalendarModal;
