import { Button, Col, Flex, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getUsers } from "../../../service";
import { SingleOrderContextProvider } from "../../organisms";
import {
  createShema,
  joiResolver,
} from "../../organisms/single-order/SingleOrder.utils";
import { useFilters } from "../../templates/filter-context/useFilters";
import { SupportedControls } from "../supported-controls/SupportedControls";

export const TaskFilter: React.FC<{ onSubmit: any; onReset: any }> = ({
  onSubmit,
  onReset,
}) => {
  const { data: users, isFetching } = useQuery<any, Error>(["/users"], () =>
    getUsers()
  );

  const { filters } = useFilters();

  const controls: any = users && [
    {
      displaying_size: 24,
      uuid: "task_name",
      field_type: "input-text",
      value: filters?.name || null,
      field_name: "Nazwa zdania",
      description: "",
      pre_name: "",
      writeable: true,
    },
    {
      writeable: true,
      displaying_size: 12,
      uuid: "status",
      field_type: "multi-select",
      value: filters?.status || null,
      options: [
        {
          label: "Utowrzono",
          value: "NEW",
        },
        {
          label: "Przypisany",
          value: "ASSIGNED",
        },
        {
          label: "W trakcie",
          value: "IN_PROGRESS",
        },
        {
          label: "Zakończone",
          value: "DONE",
        },
        {
          label: "Niepowodzenie",
          value: "FAILED",
        },
        {
          label: "Zaakceptowany",
          value: "ACCEPTED",
        },
        
      ],
      field_name: "Status",
      description: "",
      pre_name: "",
    },
    {
      writeable: true,
      displaying_size: 12,
      uuid: "priority",
      field_type: "multi-select",
      value: filters?.priority || null,
      options: [
        {
          label: "Niski",
          value: "1",
        },
        {
          label: "Średni",
          value: "2",
        },
        {
          label: "Wysoki",
          value: "3",
        },
      ],
      field_name: "Priorytet",
      description: "",
      pre_name: "",
    },
    {
      writeable: true,
      displaying_size: 24,
      uuid: "assignes",
      field_type: "multi-select",
      value: filters?.assigne || null,
      options: users.map((user: any) => {
        return {
          label: user.name,
          value: user.user_id.toString(),
        };
      }),
      field_name: "Przypisane osoby",
      description: "",
      pre_name: "",
    },
    {
      writeable: true,
      displaying_size: 24,
      uuid: "unread_comments",
      field_type: "checkbox",
      value: filters?.unread_comments || false,
      field_name: "Tylko zdania z nieprzeczytanymi komentarzami",
      description: "",
      pre_name: "",
    },
  ];

  const schema = controls && createShema(controls);
  const methods = useForm({
    resolver: schema && joiResolver(schema),
  });

  const submit = () => {
    methods.handleSubmit((data) => {
      onSubmit(data);
    }, console.error)();
  };

  const reset = () => {
    methods.reset({
      task_name: null,
      status: [],
      priority: [],
      assignes: [],
    });
    onReset();
  };

  useEffect(() => {
    methods.reset(filters);
    return;
  }, []);

  if (isFetching && !users) return <Spin size="large" />;

  return (
    <SingleOrderContextProvider disableReadMode={true}>
      <FormProvider {...methods}>
        <div style={{ width: "300px" }}>
          <Form layout="vertical">
            <Row gutter={16}>
              {controls
                ?.sort((a: any, b: any) => {
                  return a.position - b.position;
                })
                ?.map((control: any) => {
                  return (
                    <Col span={control.displaying_size} key={control.uuid}>
                      <SupportedControls control={control} />
                    </Col>
                  );
                })}
            </Row>
          </Form>
          <Flex justify={"end"} gap={8}>
            <Button onClick={reset}>Wyczyść</Button>
            <Button type="primary" onClick={submit}>
              Zatwierdz
            </Button>
          </Flex>
        </div>
      </FormProvider>
    </SingleOrderContextProvider>
  );
};
